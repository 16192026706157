.new {
  width: 100%;
  display: flex;

  .newContainer {
    flex: 6;

    .top{
      margin: 20px 20px 40px;
    }
    .bottom {
      margin: 20px;
      display: flex;
      border-radius: 5px;
      border: 1px solid rgba(234, 234, 239, 0.477);

      h1 {
        color: rgb(10, 9, 9);
        font-size: 25px;
        box-shadow: none;
      }

      .left {
        flex: 1;
        text-align: center;

        img {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .right {
        flex: 2;

        form {
          display: flex;
          flex-wrap: wrap;
          gap: 30px;
          justify-content: space-around;

          .formInput {
            width: 40%;

            label {
              display: flex;
              align-items: center;
              gap: 10px;

              .icon {
                cursor: pointer;
              }
            }

            input {
              width: 100%;
              padding: 5px;
              border: none;
              border-bottom: 1px solid gray;
            }
          }

          .imageupload{
            display: inline-block;
     
          }

          button {
            width: 150px;
            padding: 10px;
            border: none;
            background: rgb(0, 119, 225);
            color: white;
            font-weight: bold;
            cursor: pointer;
            margin-top: 10px;
          }
        }
      }
    }
  }
}
