.list {
  display: flex;
  width: 100%;

  .listContainer {
    flex: 6;
  }
}

.cellWithImg {
  border-radius: 50px;
  height: auto !important;
  width: auto !important;
}