.newContainer{
    display: flex;
    flex-direction: column;
}
.apiSocial{
    position: absolute;
    left: 14em;
    top: 4em;
}
.bttn{
    position: absolute;
    left: 25%;
    top: 16em;
   
}
.addImage:hover{
    color: red;
}