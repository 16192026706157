.edit_content_row {
  margin: 15px 0 0;
}

.editform {
  gap: 15px;
}

.editform input {
  height: 45px;
}

.editform button {
  height: 45px;
}

.toast {
  background-color: green !important;
}

.edit_content_row label {
  font-weight: 700;
  color: #000;
  margin: 0 0 4px;
}